import React, { useState, useEffect } from 'react';
import "./App.css";
import Classify from './Classify.js';

const PacketViewer = () => {
	const [packets, setPackets] = useState([]);
	const [inputText, setInputText] = useState(''); // State for the input text
	const [classificationResult, setClassificationResult] = useState(''); // State for the classification result
	const [confidence, setConfidence] = useState(null); // State for the confidence
	const [isLoading, setIsLoading] = useState(false); // State for loading

	// Function to generate random packet data
	const generateRandomPacket = () => {
		return {
			ethernet: {
				source: `00:00:5e:${Math.floor(Math.random() * 100)}:${Math.floor(Math.random() * 100)}:${Math.floor(Math.random() * 100)}`,
				destination: `00:00:5e:${Math.floor(Math.random() * 100)}:${Math.floor(Math.random() * 100)}:${Math.floor(Math.random() * 100)}`,
				type: Math.floor(Math.random() * 1000).toString(),
			},
			ip: {
				source: `${Math.floor(Math.random() * 256)}.${Math.floor(Math.random() * 256)}.${Math.floor(Math.random() * 256)}.${Math.floor(Math.random() * 256)}`,
				destination: `${Math.floor(Math.random() * 256)}.${Math.floor(Math.random() * 256)}.${Math.floor(Math.random() * 256)}.${Math.floor(Math.random() * 256)}`,
				proto: Math.floor(Math.random() * 10),
				length: Math.floor(Math.random() * 1000),
			},
		};
	};

	const fetchPackets = () => {
		setIsLoading(true);
		// Simulate fetch delay
		setTimeout(() => {
			const newPackets = Array.from({ length: 10 }, generateRandomPacket);
			setPackets(newPackets);
			setIsLoading(false);
		}, 2000); // Assuming fetching takes 2 seconds
	};

	if (isLoading) {
		return <div className="loading-message">Fetching network packets from server...</div>;
	}


	return (
		<div className="packet-viewer">
			<h1>Network Packets</h1>
			<button onClick={fetchPackets} disabled={isLoading}>
				{isLoading ? 'Loading...' : 'Refresh'}
			</button>
			<table>
				<thead>
					<tr>
						<th>Ethernet Source</th>
						<th>Ethernet Destination</th>
						<th>IP Source</th>
						<th>IP Destination</th>
						<th>Protocol</th>
						<th>Length</th>
						<th>Info</th>
					</tr>
				</thead>
				<tbody>
					{packets.map((packet, index) => (
						<tr key={index}>
							<td>{packet.ethernet?.source}</td>
							<td>{packet.ethernet?.destination}</td>
							<td>{packet.ip?.source}</td>
							<td>{packet.ip?.destination}</td>
							<td>{packet.ip?.proto}</td>
							<td>{packet.ip?.length}</td>
							<td>Additional Info</td>
						</tr>
					))}
				</tbody>
			</table>
			<Classify />
		</div>
	);
};

export default PacketViewer;
